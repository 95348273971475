import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { Component } from "react"

import { slide as Menu } from 'react-burger-menu'
import OnClickActiveArea from "./react/on-click-active-area";

import {gaPageView} from './helpers'

class MobileMenu extends Component {
    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({menuOpen: false})
    }

    render () {
        return (
            <div>
                <Menu
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                >
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#ueberuns'>Über uns</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#stickerei'>Stickerei</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#textildruck'>Textildruck</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#konfektion'>Konfektion</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#referenzen'>Referenzen</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#kataloge'>Kataloge</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onAnchorLinkClick={() => {this.closeMenu(); gaPageView(); }} className="menu-item" activeClassName="active" to='#ansprechpartner'>Ansprechpartner</AnchorLink>
                    </li>
                    <li><OnClickActiveArea className="menu-item" href="datenschutz">Datenschutz</OnClickActiveArea></li>
                    <li><OnClickActiveArea className="menu-item" href="impressum">Impressum</OnClickActiveArea></li>
                </Menu>
            </div>
        )
    }
}



export default MobileMenu